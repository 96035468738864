<p-dialog [(visible)]="isOpen" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal userAdminModal'"
  [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'height':'700px','max-width':'1370px', 'width': '100%'}"
  [resizable]="false">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between" style="padding: 10px 17px 0px 14px;">
      <span class="gfid-title" style="font-size: 16px;width: 8em;">BULK UPLOAD</span>
      <span *ngIf="showGroupName" style="font-size: 13px;color: gray;font-family: 'OpenSansLight';width: 71em;">{{selectedAdminGroupName}}</span>

      <button type="button" (click)="closeModal()" class="d-flex flex-row close qma-text" style="border:none !important;opacity:1">
        <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="gfid-body admin-gfid-padding">
    <ng-template #customLoadingTemplate>
      <div class="custom-class-loader">
        <div class="spinner-container">
          <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
            <circle cx="170" cy="170" r="160" stroke="#021e48" />
            <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
            <circle cx="170" cy="170" r="110" stroke="#021e48" />
            <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
          </svg>
        </div>
      </div>
    </ng-template>
    <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
    </ngx-loading>
    <div class="w-80">
      <p-tabView class="adminview adminSetup" #adminTabs [styleClass]="'admin-tabview'"  [style]="{'height':'650px','width':'90%','max-width':'1190px','margin-left':'1.25rem',overflow: 'hidden', 'position': 'relative'}"
        [activeIndex]="actTabIdx">
        <p-tabPanel header="BULK GROUP SETUP" class="tab-heading userGroupAssignment">
          <div class="row">
            <div class="col text-right">
              <label class="flex-grow" (click)="downloadGroupTemplate()"><img
                  src='assets/Inbox/download_icon_u70.svg' alt="Download">Download form</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3 pull-right">
              <label class="flex-grow pull-right">Select a group setup file (.csv) : </label>
            </div>
            <div class="col-9">
              <p-fileUpload class="bulksetup" method="POST" name="myfile[]" customUpload="true" (uploadHandler)="onGroupFilesUpload($event)"
                (onSelect)="fileSelectGroup()" accept=".csv">
              </p-fileUpload>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
            </div>
            <div class="col-9">
              <p-checkbox class="mr-2" [(ngModel)]="uploadPartiallyValidGroups" binary="true"></p-checkbox>
              <label class="flex-grow mb-0">Please select this option to upload partially valid records</label>
            </div>
            <div *ngIf="displayGrpMsg" class="alert alert-danger alertrm" role="alert">
              {{grpMsg}}
            </div>
          </div>
          <div *ngIf="displayGrpTable">
            <p-table [value]="failGrpMsg">
              <ng-template pTemplate="header">
                <tr>
                  <th id="srNo">Sr No</th>
                  <th id="gfcidRec">GFCID Record</th>
                  <th id="valRes">Validation Result</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-failedmsg>
                <tr>
                  <td>{{failedmsg.row}}</td>
                  <td>{{failedmsg.lineNo}}</td>
                  <td>{{failedmsg.validation}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="BULK GFCID SETUP" class="tab-heading">
          <div class="row">
            <div class="col text-right">
              <label class="flex-grow" (click)="downloadGFCIDTemplate()"><img
                  src='assets/Inbox/download_icon_u70.svg' alt="Download">Download form</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3 pull-right">
              <label class="flex-grow pull-right">Select setup file (.csv, .txt) : </label>
            </div>
            <div class="col-9">
              <p-fileUpload class="bulksetup" method="POST" name="myfile[]" customUpload="true" (uploadHandler)="onGFCIDFilesUpload($event)"
                (onSelect)="fileSelectGfcid()" accept=".csv, .txt">
              </p-fileUpload>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
            </div>
            <div class="col-9">
              <p-checkbox class="mr-2" [(ngModel)]="uploadPartiallyValidGFCIDs" binary="true"></p-checkbox>
              <label class="flex-grow mb-0">Please select this option to upload partially valid records</label>
            </div>
            <div *ngIf="displayGfcid" class="alert alert-danger" role="alert">
              {{gfcidMsg}}
            </div>
          </div>
          <div *ngIf="displayGfcidTable">
            <p-table [value]="failGfcidMsg">
              <ng-template pTemplate="header">
                <tr>
                  <th id="srNo">Sr No</th>
                  <th id="lineNo">Line No</th>
                  <th id="valFail">Validation Failures</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-gfcidmsg>
                <tr>
                  <td>{{gfcidmsg.row}}</td>
                  <td>{{gfcidmsg.lineNo}}</td>
                  <td>{{gfcidmsg.validation}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Request Type Mappings" class="tab-heading">
          <div class="row">
            <div class="col text-right">
              <label class="flex-grow" (click)="downloadRTMTemplate()"><img
                  src='assets/Inbox/download_icon_u70.svg' alt="Download">Download form</label>
            </div>
          </div>
          <div class="row">
            <div class="col-4 pull-right">
              <label class="flex-grow pull-right">Select a request type mapping file (.csv, .txt) : </label>
            </div>
            <div class="col-8">
              <p-fileUpload class="bulksetup" method="POST" name="myfile[]" customUpload="true" (uploadHandler)="onRTMFilesUpload($event)"
                (onSelect)="fileSelect()" accept=".csv, .txt">
              </p-fileUpload>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
            </div>
            <div class="col-9">
              <p-checkbox class="mr-2" [(ngModel)]="uploadPartiallyValidRTMs" binary="true"></p-checkbox>
              <label class="flex-grow mb-0">Please select this option to upload partially valid records</label>
            </div>
          </div>
          <div *ngIf="displayRtmMsg" class="alert alert-danger alertrm" role="alert">
            {{rtmMessage}}
          </div>
          <div *ngIf="displatRtmTable">
            <p-table [value]="failRtmMsg">
              <ng-template pTemplate="header">
                <tr>
                  <th id="srNo">Sr No</th>
                  <th id="lineNo">Line No</th>
                  <th id="valFail">Validation Failures</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-failitem>
                <tr>
                  <td>{{failitem.row}}</td>
                  <td>{{failitem.lineNo}}</td>
                  <td>{{failitem.validation}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <!-- MIS Exclusion tab -->
        <p-tabPanel header="MIS EXCLUSION" class="tab-heading">
          <div class="container">
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Group: </label>
              </div>
              <div class="col-9">
                <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50" [(ngModel)]="selectedMISExclusionGroup" filter="true"
                  [options]="groupsList" (onChange)="loadMISExlusionReports()">
                </p-dropdown>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Report Name: </label>
              </div>
              <div class="col-9">
                <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50" [(ngModel)]="selectedReportName" (onChange)="loadMISExlusionReports()"
                  [options]="reportNamesList" filter="true">
                </p-dropdown>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Exclusion Reason: </label>
              </div>
              <div class="col-9">
                <p-dropdown [styleClass]="'prime-dd-override '" class="drop-down w-50" [(ngModel)]="selectedExclusionReason" [options]="exclusionReasonsList"
                  filter="true">
                </p-dropdown>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Exclusion End Date: </label>
              </div>
              <div class="col-9">
                <p-calendar class="" dateFormat="mm/dd/yy" [(ngModel)]="exclusionEndDate" [hideOnDateTimeSelect]="true" [styleClass]="'report-range-cal advance-calender schedule-cal tab-calender-fix'"
                  [readonlyInput]="false" [showIcon]="true" [baseZIndex]="10000" [minDate]="minDate" [style]="{'width': '200px'}">
                </p-calendar>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Comments: </label>
              </div>
              <div class="col-9 mt-2">
                <textarea [rows]="5" [cols]="20" pInputTextarea [(ngModel)]="misExclusionComments" class="w-50  t-box inp-txt-mng group-details-input-mng"></textarea>
              </div>
            </div>
            <div *ngIf="IsMISExclusionError" class="alert alert-danger" role="alert">
              {{IsMISExclusionErrorMsg}}
            </div>
            <!-- <p class="has-error" *ngIf="IsMISExclusionError!=''">{{IsMISExclusionError}}</p> -->
            <div class="row p-2">
              <div class="offset-3 col-9 text-left">
                <button type="button" class="btn save-btn mr-4" (click)="saveMISExclusionChanges()">Save</button>
              </div>
            </div>
            <div *ngIf="rowData && rowData.length>0 ">
              <div class="row p-2">
                <div class="col-12 pull-right">
                  <ag-grid-angular id="adminBulkupload" style="width: 100%;" class="ag-theme-balham message-grid mailbox-grid-unique-class grid-style"
                    [rowData]="rowData" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [modules]="modules" [rowStyle]="rowStyle"
                    (gridReady)="onGridReady($event)" [rowSelection]="rowSelection" [suppressPaginationPanel]="false" [paginationPageSize]="5"
                    [pagination]="false" (paginationChanged)="onPaginationChanged()" (selectionChanged)="onSelectionChanged($event)"
                    suppressMenu=true [rowHeight]="50" [headerHeight]="44"></ag-grid-angular>
                </div>
                <div class="col-1"></div>
              </div>
              <div class="row p-2">
                <div class="col-5"></div>
                <div class="col-2">
                  <button type="button" class="btn deact-btn mr-4" (click)="deactivateMISExclusion()">Deactivate</button>
                </div>
                <div class="col-5"></div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <!-- Bulk Group Resolve -->
        <p-tabPanel header="BULK GROUP RESOLVE" class="tab-heading">
          <div class="container">
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Group: </label>
              </div>
              <div class="col-9">
                <p-dropdown [styleClass]="'prime-dd-override '" id="bulkGroupResolveGroupId" class="drop-down w-50" [(ngModel)]="selectedBulkResolveGroup"
                  filter="true" [options]="groupsList">
                </p-dropdown>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <label for="from" class="flex-grow pull-right">Comments: </label>
              </div>
              <div class="col-9 mt-2">
                <textarea [rows]="5" [cols]="20" pInputTextarea class="w-50  t-box inp-txt-mng group-details-input-mng" id="bulkGroupResolveCommentsEditor"
                  [(ngModel)]="bulkResolveComments"></textarea>
              </div>
            </div>
            <div *ngIf="IsBulkGroupResolveError" class="alert alert-danger" role="alert">
              {{validationError}}
            </div>
            <!-- <p class="has-error" *ngIf="IsBulkGroupResolveError!=''">{{IsBulkGroupResolveError}}</p> -->
            <div class="row p-2">
              <div class="offset-3 col-9 text-left">
                <button type="button" class="btn save-btn mr-4" (click)="saveBulkResolveChanges()">Save</button>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="RELEASE NOTES" class="tab-heading">

          <div class="row">
            <div class="col-3 pull-right">
              <label class="flex-grow pull-right">Select Release Note File(.pdf) : </label>
            </div>
            <div class="col-9">
              <p-fileUpload class="releasenote" method="POST" name="myfile[]" customUpload="true" (uploadHandler)="handleFileInput($event)"
                 accept=".pdf">
              </p-fileUpload>
            </div>
          </div>

         <div *ngIf = "postReleaseNoteUpload">
          <div *ngIf = "!releaseNoteUploadedSuccessfully" class="alert alert-danger alertrm" role="alert">
            {{successOrErrorMsg}}
         </div>
          <div *ngIf = "releaseNoteUploadedSuccessfully"  class="alert alert-success" role ="alert">
            {{successOrErrorMsg}}
          </div>
        </div>
        </p-tabPanel>


      </p-tabView>
    </div>
  </div>
  <p-toast></p-toast>
</p-dialog>
<!--sucess message for resolve group-->
<p-dialog [(visible)]="successResolveGroup" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true" [dismissableMask]="true" [baseZIndex]="10000"
  [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Success Confirmation</h6>
    </div>
  </p-header>
  <div class="success-msg">{{successResolveGroupMessage}}</div>
</p-dialog>
<!--error message for resolve group-->
<p-dialog [(visible)]="errorResolveGroup" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal failureModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Information</h6>
    </div>
  </p-header>
  <div class="success-msg">{{errorResolveGroupMessage}}</div>
</p-dialog>

<!--sucess message for MIS Exclusion-->
<p-dialog [(visible)]="successMISExclusion" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true" [dismissableMask]="true" [baseZIndex]="10000"
  [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Success Confirmation</h6>
    </div>
  </p-header>
  <div class="success-msg">{{successMISExclusionMessage}}</div>
</p-dialog>
<!--error message for MIS Exclusion-->
<p-dialog [(visible)]="errorMISExclusion" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal failureModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Information</h6>
    </div>
  </p-header>
  <div class="success-msg">{{errorMISExclusionMessage}}</div>
</p-dialog>
<!--error message for MIS Exclusion-->
<p-dialog [(visible)]="deactivateStatus" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal failureModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Success Confirmation</h6>
    </div>
  </p-header>
  <div class="success-msg">Selected reports be now be deactive</div>
</p-dialog>
<p-dialog [(visible)]="isUploadSuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">{{errAndSuccess}}</h6>
    </div>
  </p-header>
  <div class="success-msg">{{errorMessageForGroupAdmin}}</div>
</p-dialog>