<p-dialog [(visible)]="isOpen" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal userAdminModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000"
  [closeOnEscape]="true" [style]="{'height':'580px','max-width':'1204px', 'width': '90%', 'max-height':'100%'}" [resizable]="false">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between" style="padding: 20px 27px 0px 24px;">
      <span class="gfid-title" style="font-size: 20px;width: 12em;">CONFIG ADMINISTRATION</span>
      <span *ngIf="showGroupName"
        style="font-size: 13px;color: gray;font-family: 'OpenSansLight';width: 71em;">{{selectedAdminGroupName}}</span>

      <button type="button" (click)="closeModal()" class="d-flex flex-row close qma-text"
        style="border:none !important;opacity:1">
        <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="gfid-body admin-gfid-padding">
    <ng-template #customLoadingTemplate>
      <div class="custom-class-loader">
        <div class="spinner-container">
          <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
            <circle cx="170" cy="170" r="160" stroke="#021e48" />
            <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
            <circle cx="170" cy="170" r="110" stroke="#021e48" />
            <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
          </svg>
        </div>
      </div>
    </ng-template>
    <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
    </ngx-loading>
    <div class="w-80">
      <p-tabView class="adminview" #profileTabs (onChange)="onTabChange($event)" [styleClass]="'admin-tabview'"
      [scrollable]="false"
        [style]="{'height':'540px','width':'90%','max-width':'1050px','margin-left':'1.25rem',overflow: 'hidden', 'position': 'relative'}"
        [activeIndex]="actTabIdx">
        <p-tabPanel *ngIf= "isDBAdmin" header="CONFIG ADMIN" class="tab-heading">
          <div class="pr-2">
            <div class="row p-2">
              <div class="col-5">
                <p-dropdown [styleClass]="'prime-dd-override'" placeholder="Select Collection" class="drop-down w-50"
                  [(ngModel)]="selectedCollection" [options]="databaseCollection">
                </p-dropdown>
              </div>
              <div class="col-1"></div>
              <div class="col-6">
                <input type="text" [(ngModel)]="documentId" placeholder="Enter Document ID"
                  class="t-box w-50 inp-txt-mng " style="margin-left:12%;padding-top:2%;" />
              </div>
            </div>
            <div class="row p-2">
              <div class="col-12">
                <textarea pInputTextarea [(ngModel)]="document" class="document-viewer"></textarea>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-3">
                <button type="button" class="btn save-btn pull-left" (click)="backup()">Backup</button>
              </div>
              <div class="col-3">
                <button type="button" class="btn save-btn pull-left" (click)="search()">Search</button>
              </div>
              <div class="col-3">
                <button type="button" class="btn save-btn pull-right" (click)="validate()">Validate</button><br><br>
              </div>
              <div class="col-3">
                <button type="button" class="btn save-btn pull-right" (click)="update()">Update</button>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="CONFIG REFRESH" class="tab-heading">
          <div class="pr-2">
            <div class="row p-2">
              <!-- <div class="col-2"></div> -->
              <div class="col-4">
                <p-dropdown [styleClass]="'prime-dd-override'" placeholder="Select Collection" class="drop-down w-50"
                  [(ngModel)]="selectedConfigCollection" [options]="configCollection">
                </p-dropdown>
              </div>
              <div class="col-2"></div>
              <div class="col-3 pull-right">
                <button type="button" class="btn refresh-btn" (click)="refresh()">Refresh</button>
              </div>
              <div class="col-1"></div>
            </div>
            <div *ngIf="refreshReport" class="row p-2">
              <div class="col-2"></div>
              <div class="col-9 pull-right">
                  <div *ngIf="displaySuccessMessage" class="display-success">
                    <em class="fa fa-check"></em>
                    {{refreshStatus}}
                  </div>
                  <div *ngIf="!displaySuccessMessage" class="display-fail">
                    <em class="fa fa-check"></em>
                    {{refreshStatus}}
                  </div>
                <!-- <ag-grid-angular class="ag-theme-balham message-grid mailbox-grid-unique-class grid-style"
                [defaultColDef]="defaultColDef" [rowData]="refreshReport" [columnDefs]="columnDefs"
                [modules]="modules" [rowStyle]="rowStyle">
              </ag-grid-angular> -->
              </div>
              <div class="col-1"></div>
            </div>
          </div>
        </p-tabPanel>
        <p-footer class="fix-footer">
          <div class="d-flex flex-row footer-btn-container"></div>
          <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button class="btn clear-btn" (click)="reset()">Reset</button>
          </div>
        </p-footer>
      </p-tabView>
    </div>
  </div>
  <p-toast></p-toast>
</p-dialog>
<!--for error message -->
<p-confirmDialog header="Confirm Action" width="425" rejectIcon="No" acceptIcon="No" [appendTo]="'body'">
</p-confirmDialog>
<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true" [dismissableMask]="true"
  [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Information</h6>
    </div>
  </p-header>
  <div class="success-msg">{{errMsg}}</div>
</p-dialog>
<!--for success message-->
<p-dialog [(visible)]="displaySuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
  [styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true" [dismissableMask]="true"
  [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Success Confirmation</h6>
    </div>
  </p-header>
  <div class="success-msg">{{successMessage}}</div>
</p-dialog>