<p-sidebar
  [(visible)]="isVisible"
  [position]="'right'"
  [modal]="false"
  [showCloseIcon]="false"
  styleClass="sideBarStyle"
  (onHide)="hideSlideBar($event)"
>
  <div class="panelHeader">
    <div
      [ngClass]="{panelTabActive: activeIndex === 0, panelTab: activeIndex !== 0}"
      (click)="onTabChange(0)"
    >
      Docs
    </div>
    <div
      [ngClass]="{panelTabActive: activeIndex === 1, panelTab: activeIndex !== 1}"
      (click)="onTabChange(1)"
    >
      Audit
    </div>
    <div
      [ngClass]="{panelTabActive: activeIndex === 2, panelTab: activeIndex !== 2}"
      (click)="onTabChange(2)"
    >
      Notes
    </div>
    <div
      [ngClass]="{panelTabActive: activeIndex === 3, panelTab: activeIndex !== 3}"
      (click)="onTabChange(3)"
    >
      Contacts
    </div>
    <div
      class="panelClose"
      (click)="hideSlideBar($event)"
      pTooltip="Close"
      tooltipPosition="top"
      tooltipStyleClass="rightTooltip p-tooltip-text"
    >
      <img
        [src]="'assets/Inbox/rightPanelClose.svg'"
        alt="Close"
        class="closeIcon"
      />
    </div>
  </div>

  <div *ngIf="activeIndex === 0">
    <app-attachment [attachments]="attachmentList"></app-attachment>
  </div>
  <div *ngIf="activeIndex === 1">
    <app-audit-detail [audits]="audits"></app-audit-detail>
  </div>
  <div *ngIf="activeIndex === 2">
    <app-notes-details
      [notes]="notes"
      [selectedInquiryId]="selectedInquiryId"
      [assignedGroupName]="assignedGroupName"
      (noteAdded)="onNotesAdded($event)"
    ></app-notes-details>
  </div>
  <div *ngIf="activeIndex === 3">
    <contact-list
      (contactEvent)="onPushContact($event)"
      [fromGroupName]="groupName"
      [topContactAdded]="topContactAdded"
    ></contact-list>
  </div>
  <!-- <p-tabView
    [activeIndex]="activeIndex"
    class="pTabStyle"
    (onChange)="onTabChange($event)"
  >
    <p-tabPanel header="DOCS">
      <ng-template pTemplate="content">
        <app-attachment [attachments]="attachmentList"></app-attachment>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="AUDIT">
      <ng-template pTemplate="content">
        <app-audit-detail [audits]="audits"></app-audit-detail>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="NOTES">
      <ng-template pTemplate="content">
        <app-notes-details
          [notes]="notes"
          [selectedInquiryId]="selectedInquiryId"
          [assignedGroupName]="assignedGroupName"
          (noteAdded)="onNotesAdded($event)"
        ></app-notes-details>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="CONTACTS" cache="true" *ngIf="!hideContactsForTaskize">
      <ng-template pTemplate="content">
        <contact-list
          (contactEvent)="onPushContact($event)"
          [fromGroupName]="groupName"
          [topContactAdded]="topContactAdded"
        ></contact-list>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel cache="true" (click)="hideSlideBar($event)">
      <ng-template pTemplate="header">
        <img [src]="'assets/Inbox/rightPanelClose.svg'" alt="Close" class="closeIcon" 
      /></ng-template>
    </p-tabPanel>
  </p-tabView> -->
</p-sidebar>

<!-- END of Right Panel Components -->
<p-dialog
  [(visible)]="displayClc"
  [modal]="true"
  [responsive]="true"
  [maximizable]="false"
  [appendTo]="'body'"
  [styleClass]="'contextMenuModal CLCclass'"
  [closable]="false"
  [dismissableMask]="true"
  [baseZIndex]="10000"
  [closeOnEscape]="true"
  [style]="{'min-height':'18em','max-height':'31em','width':'93em','top':'64px !important','margin-bottom':'16em'}"
>
  <div *ngIf="displayClc">
    <app-clc
      [clc]="clc"
      (closeClc)="closeClc($event)"
      (tradesEvent)="onSelectedTrades($event)"
    ></app-clc>
  </div>
</p-dialog>
