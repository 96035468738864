
<p-dialog styleClass="notes-overlay" [(visible)]="isVisible" modal="true" [style]="{ 
    width: '488px',  
    height:'340px',
    borderRadius: '25px' 
}">
    <div class="notes-modal-content">
        <div class="header">
            <span>Notes</span>
            <div   class="close-button" (click)="closeModal()"><img src="assets/Inbox/close_icon_white.svg"></div>
        </div>
        <div class="content">
            <label for="notes">Note:</label>
            <textarea id="notes" rows="5" [(ngModel)]="noteText" maxlength="250"></textarea>
            <!-- <div class="char-count">{{ noteText.length }}/250</div> -->
        </div>
        <div class="footer">
            <button pButton label="Cancel" class="cancel-button" (click)="closeModal()"></button>
            <button pButton label="Submit" class="submit-button" (click)="submitNotes()"></button>
        </div>
    </div>
</p-dialog>
