<nav class="top-bar search-{{isMobileSearchView }} d-flex align-items-center flex-row flex-nowrap px-3" [ngClass]="{'justify-end': searchboxClicked}">
    <div id="topmenuhide1" [ngClass]="{'hide-topmenu': searchboxClicked}">
        <div class='d-flex flex-row w-100 align-items-center justify-content-center '>

            <img class="medium-logo" src='assets/qwyn/icons/white-logo.svg' alt="Show">


            <span class='nav-items' (click)="selectMenuItem('dashboard',0)"
                [ngClass]="{'active': selectedMenu === 'dashboard'}">Dashboard</span>
            <p class='nav-items' (click)="selectMenuItem('messages',1);"
                [ngClass]="{'active': selectedMenu === 'messages'}">
                Messages</p>
            <p class='nav-items' (click)="selectMenuItem('reports',2)" [ngClass]="{'active': selectedMenu === 'reports'}">
                Reports</p>
            <p class='nav-items' (click)="selectMenuItem('groupAdmin',3)"
                [ngClass]="{'active': selectedMenu === 'groupAdmin'}">
                Group Admin</p>
        </div>
    </div>
    <div class='d-flex flex-row align-items-center justify-content-center ' id='right-side-options'>

        <!-- Searchbox with dropdown section starts -->
        <div *ngIf='searchboxClicked' class="search" [ngClass]="{'search-focused':inputFocused}">
            <div appOuterClick (outsideClick)="onInputClick(false);clearItem(autocomplete)" class="navbar-left">
                <form class="form-horizontal" [formGroup]="searchForm" (keydown.enter)="$event.preventDefault()">
                    <div *ngIf="isMobile && !inputFocused" class="sidebaricon">
                        <div class="topnav-icons w3-left w3-bar-item w3-button" id="mobileMenuClick"
                            (click)="showSlide($event)">
                            <img src="assets/mobile/hamburger.svg" alt="">
                        </div>
                    </div>
                    <div *ngIf="isMobile && inputFocused" (click)="enableBackButton()">
                        <img class="backward-icon" src="assets/Inbox/right_chevron_icon.svg" alt="">
                    </div>
                    <div class="mobile_header_text flex-row align-items-center justify-content-start">
                        <div class="mobile-label">{{currentTab.header}}</div>
                        <div *ngIf="currentTab.count" class="custom-badge">{{displayEllipsis(currentTab.count)}}</div>
                    </div>
                    <div class="input-group">
                        <div class="input-group-prepend searchBoxLeft" style="display:none">
                            <button [hidden]="!inputFocused" (click)="triggerSearch($event)" class="btn btn-default"
                                name="searchBtn1">
                                <span class="searchsection advance-search-magnifier">
                                    <!-- <em class="topnav-icons fa fa-search"></em> -->
                                </span>
                            </button>
                        </div>
                        <div >
                            <p-dropdown class="drop-down w-100 from-section custom-border-radius" [styleClass]="'prime-dd-advance-search'"
                                [options]="searchInFolders" [dropdownIcon]="'none'"
                                [style]="{'margin-left': '10px','background': '#2B7DF8','border-color': 'rgb(43, 125, 248)'}"
                                (onChange)="onsearchInFolderChange($event)" formControlName="frmCtrlsearchInFolders">
                                <ng-template let-folder pTemplate="selectedItem">
                                    <span class="smart-search-ddselecteditem" style='border-radius:4px;padding-left: 8px !important'>
                                        {{folder.label}}
                                    </span>
                                </ng-template>
                                <ng-template let-folder pTemplate="item">
                                    <div class="ui-helper-clearfix" style="position: relative;height: 25px; align-content: center;">
                                        <div class="smart-search-dditems" style='margin-left: 8px !important'>{{folder.label}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <!-- search box with auto populate -->
                        <div class="search-text-container" (click)="toggleSearchInput()"
                            [ngClass]=" inputFocused ? 'search-box-expanded' : 'search-box-collapsed'">
                            <p-autoComplete [suggestions]="savedSeachSingle"
                                (completeMethod)="filterSearchSingle($event)" field="name" [size]="30"
                                [placeholder]="txtPlaceholder" [minLength]="1"
                                [style]="{'width':'100%','border': 'none','font-size':'12px','font-family':'OpenSans','color':'#002d72'}"
                                [inputStyle]="{'width':'100%','border': 'none','font-size':'12px','font-family':'OpenSans','color':'#002d72','padding-left': '8px', 'height':'32px'}"
                                (onClear)="clearItem(autocomplete)"
                                (keypress)="onAutoCompleteSearch($event)" (onKeyUp)="onAutoCompleteKeyUp($event)"
                                #autocomplete formControlName="searchText"
                                (keyup.enter)="callSearch($event,true,autocomplete)"
                                styleClass="override override-newmessage-autocomplete-topnav mob-mt5 searchInputWidth"
                                [maxlength]="100"></p-autoComplete>
                               <!-- (onFocus)="!autocomplete.value && autocomplete.handleDropdownClick()" -->
                        </div>
                        <!--  <span class="advance-search-reset" [hidden]="!inputFocused"></span> -->
                        <span  style="cursor: pointer;position: absolute; top:7px; font-family: 'OpenSans'; font-weight: 400; font-size: 12px;
                           right: 36px;">{{searchTextLength}} /{{smartSearchCharactersLimit}}
                            <!-- <img id='cancel-search-topnav' src="assets/newMessage/cancel.svg" alt="Cancel"
                                [hidden]="!inputFocused" (click)="closeSearchArea()"> -->
                        </span>
                        <div class="input-group-btn searchBox">

                            <button class="btn btn-default adv-search-button"
                                (click)="toggleSearch()">

                                <a style="display: inline-block;position: relative; top:-2px" [class.active]='clickStatusArray[0]'>

                                    <img [src]="!clickStatusArray[1] ? 'assets/qwyn/icons/filter.svg':'assets/qwyn/icons/filter.svg'"
                                        alt="">
                                </a>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-content-end align-items-center header-options">
                        <div class="btn mobile-searchicon px-1" *ngIf="showMultiSelection && isMobile"
                            name="selectBtnMobile" (click)="mobileSelectClick($event)">
                            <div [ngClass]="(inboxService.mobileCheckboxSelected)?'isChecked':'isUnChecked'"></div>
                        </div>
                        <div class="mobile-sort-label px-1" *ngIf="showMultiSelection && isMobile"
                            (click)="mobileSortingClick($event,addToGrp)">
                            {{sortAttrLabel()}}
                        </div>
                        <div class="mobile-sort-icon px-1" *ngIf="showMultiSelection && isMobile"
                            (click)="mobileSortDirection($event,addToGrp)">
                            <!--<img src='assets/mobile/sort.svg' alt="">-->
                            <span [ngClass]="sortDirectionClass()"></span>
                        </div>
                        <div style="position:relative;" class="d-flex flex-row">
                            <p-overlayPanel [styleClass]="'mob-inbox-sorting sorting inbox-sorting'" [appendTo]="'body'"
                                #addToGrp [baseZIndex]="9999" [dismissable]="true">
                                <div class="d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="radioMobSort">SORT BY</h6>
                                    <div class="close-leftNav" (click)="closeSortingModal($event,addToGrp)">
                                        <img src='assets/mobile/close_sort.svg' alt="Sort">
                                    </div>
                                </div>
                                <form [formGroup]="myForm">
                                    <div class="ui-g mobsorting-radio" style="width:250px;margin-top:6px">
                                        <div class="ui-g-12" *ngFor="let attr of sortAttrNames; let i = index">
                                            <p-radioButton class="radioBulkUpload" name="myRadio" value="{{i}}"
                                                label="{{attr}}" [(ngModel)]="sortAttrIndex" formControlName="myRadio"
                                                inputId="preopt{{i}}"></p-radioButton>
                                        </div>
                                    </div>
                                </form>
                            </p-overlayPanel>
                        </div>
                        <div *ngIf="showMultiSelection && isMobile"
                            class="d-flex flex-row align-items-center mobile-search-btn px-1"
                            (click)="mobileSearchClick()">
                            <img class="mobile-search-icon" src='assets/mobile/search.svg' alt="">
                        </div>
                    </div>
                    <div *ngIf="!firstClick" [hidden]="!advanceSearchVisible" class="advance-holder">
                        <app-adv-criteria (searchToggle)="toggleSearch()"></app-adv-criteria>
                    </div>
                </form>
            </div>
            <div *ngIf="showSearchError" class="invalid-feedback">

                <div><img src="assets/newMessage/error.svg" class="error" alt="Error"> Please provide minimum of 3
                    characters
                </div>
            </div>
        </div>
        <!-- Searchbox with dropdown section ends -->
        <ul class="d-flex align-items-center flex-row flex-nowrap mb-0" [ngClass]="checkIfActive()" id="topmenuhide">
            <div *ngIf='!searchboxClicked' style='margin-right: 30px !important;' [ngClass]="{'search-focused':inputFocused}">
                <div appOuterClick (outsideClick)="resetSearchBox($event)" class="navbar-left">
                    <form class="form-horizontal" [formGroup]="searchForm" (keydown.enter)="$event.preventDefault()">
                        <input type="text" pInputText [(ngModel)]="value" placeholder="Search" variant="filled"
                            class="custom-filled-input" (click)="onInputClick(true)" (mouseenter)="onInputClick(true)"/>
                        <img class="search-icon" [src]="'assets/qwyn/icons/search-icon.svg'" alt="">
                    </form>
                </div>
            </div>
            <div class="separator"></div>
            <li class="notification-icon"><a [class.active]='clickStatusArray[1]'>
                    <img [src]="!clickStatusArray[1] ? 'assets/qwyn/icons/vector.svg':'assets/qwyn/icons/vector.svg'"
                        alt="">
                </a>
            </li>
            <div class="separator"></div>
            <li class="notification-icon" [class.notify]="notificationCount>0">
                <a style="display: inline-block;position: relative;" (click)="onOptionClick(0)" [class.active]='clickStatusArray[0]'>
                    <span class="notify-badge">{{notificationCount}}</span>
                    <img [src]="!clickStatusArray[1] ? 'assets/qwyn/icons/notification.svg':'assets/qwyn/icons/notification.svg'"
                        alt="">
                </a>
            </li>

            <div class="separator"></div>
            <li class="profile-icon">
                <a (click)="onOptionClick(2)" [class.active]='clickStatusArray[2]'>
                    <img [src]="profilePicSrc" alt="" *ngIf="AuthKey">
                    <div class="display-individual" *ngIf="!AuthKey">{{userId.substring(0,2)}}</div>
                </a>
            </li>
        </ul>
    </div>

</nav>
<!-- added for show release note start*/ -->
<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal currentRelease releaseNote'" [closable]="false" [dismissableMask]="true"
    [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width':'44%','height':'60%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title" style="font-weight: bold;">Current Release Notes</h6>
        </div>
    </p-header>

    <iframe id="releaseNotesIframeId" width="100%" height="80%" style="border-color: black;  border-width: 3px"
        [src]="releaseNoteUrl | safeUrl" title="Release Notes"></iframe>

    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn search-btn mr-4" (click)="updateReleaseNotesDocument()">OK</button>
        </div>
    </p-footer>

</p-dialog>
<!-- added for show release note end */ -->
<div>
</div>