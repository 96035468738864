import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewMessageRecipentComponent } from './new-message-recipent/new-message-recipent.component';
import { NewMessageActionsComponent } from './new-message-actions/new-message-actions.component';
import { ContactSidebarComponent } from './contact-sidebar/contact-sidebar.component';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ContactListComponent } from './contact-list/contact-list.component';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InboxArtifactsModule } from '../inbox-artifacts/inbox-artifacts.module';
import { ListboxModule } from 'primeng/listbox';
import { CalendarModule } from 'primeng/calendar';
import { ScheduleMessageComponent } from 'src/app/new-message/schedule/schedule-message.component';
import { ToastModule } from 'primeng/toast';
import { CmcsearchComponent } from './cmcsearch/cmcsearch.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { ClcComponent } from './contact-sidebar/clc/clc.component';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NewMessageComponent } from './new-message.component';
import { SharedModule } from '../common/shared-module';
import { DragDropDirective } from '../common/directives/drag-drop.directive';
import { TooltipModule } from 'primeng/tooltip';
import { RecipentDataComponent } from './recipent-data/recipent-data.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from '@dchtools/ngx-loading-v18';
import { DisableMultiClickDirective } from '../common/directives/disable-multiclick.directive';
import { FileDragEnterDirective } from '../common/directives/file-drag-enter.directive';
import { CorpAddBookComponent } from './corp-add-book/corp-add-book.component';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ContactSidepanelComponent } from './contact-sidepanel/contact-sidepanel.component';
@NgModule({
  declarations: [NewMessageComponent, NewMessageRecipentComponent, NewMessageActionsComponent, ContactSidebarComponent, ContactListComponent, ScheduleMessageComponent, CmcsearchComponent,
    ClcComponent, DragDropDirective, RecipentDataComponent, DisableMultiClickDirective, FileDragEnterDirective, CorpAddBookComponent, ContactSidepanelComponent],
  imports: [
    CommonModule,
    SharedModule,
    DropdownModule,
    FormsModule,
    DialogModule,
    ReactiveFormsModule,
    ButtonModule,
    SidebarModule,
    TabViewModule,
    AutoCompleteModule,
    NgScrollbarModule,
    OverlayPanelModule,
    MultiSelectModule,
    ConfirmDialogModule,
    InboxArtifactsModule,
    ListboxModule,
    CalendarModule,
    ToastModule,
    AgGridModule,
    AccordionModule,
    InputSwitchModule,
    TooltipModule,
    ScrollingModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#021e48',
      secondaryColour: '#021e48',
      tertiaryColour: '#021e48',
      fullScreenBackdrop: true
    })
  ],
  exports: [NewMessageComponent, NewMessageRecipentComponent, NewMessageActionsComponent, ContactSidebarComponent, ScheduleMessageComponent, ContactSidepanelComponent]
})
export class NewMessageModule { }
